<template>
  <div class="page-other">
    <div class="page-other-title">
      <div class="back" @click="() => this.$router.back()"><i class="el-icon-back"></i></div>
      <div class="text">账号设置</div>
    </div>
    <div class="page-other-content">
      <Card title="解绑账号">
        <el-form label-width="120rem" label-position="left" class="ml-3">
          <el-form-item label="鸿小菌账号">
            <span class="mr-2">{{ username }}</span>
            <el-button size="mini" type="primary" @click="handleClick(1)">解绑企业微信</el-button>
          </el-form-item>
        </el-form>
        <el-dialog :visible.sync="visible" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
          <el-result icon="success" title="解绑成功" subTitle="企业微信端小程序账号信息已解绑，请重新登录鸿小菌！">
            <template slot="extra">
              <el-button type="primary" size="medium" @click="handleClick(0)">确定</el-button>
            </template>
          </el-result>
        </el-dialog>
      </Card>
			<Card :title="['修改密码','更换账号']" class="w250" @onItem="onItem">
				<el-form v-show="index===1" ref="ruleForm" :model="ruleForm" :rules="rules" class="ml-3" label-position="left" label-width="120rem" status-icon>
          <el-form-item label="一号通账号">{{ username }}</el-form-item>
					<el-form-item label="新账号" prop="new_account">
						<el-input v-model="ruleForm.new_account" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="请输入新账号" type="number"></el-input>
          </el-form-item>
					<el-form-item label="确认账号" prop="new_account_confirm">
						<el-input v-model="ruleForm.new_account_confirm" oninput="if(value.length>11)value=value.slice(0,11)" placeholder="请确认账号" type="number"></el-input>
          </el-form-item>
					<el-form-item label="新密码" prop="password">
						<el-input v-model="ruleForm.password" placeholder="请输入新密码"></el-input>
					</el-form-item>
					<el-form-item label="确认新密码" prop="re_password">
						<el-input v-model="ruleForm.re_password" placeholder="请确认新密码"></el-input>
					</el-form-item>
          <el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm',0)">确认修改</el-button>
						<el-button @click="resetForm('ruleForm')">取消</el-button>
					</el-form-item>
				</el-form>
				<el-form v-show="index===0" :model="ruleForm1" class="ml-3" label-position="left" label-width="120rem" status-icon>
					<el-form-item label="原密码" prop="oldPassword" required>
						<el-input v-model="ruleForm1.oldPassword" placeholder="请输入原密码"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="password" required>
						<el-input v-model="ruleForm1.password" placeholder="请输入新密码"></el-input>
					</el-form-item>
					<el-form-item label="确认新密码" prop="confirmPassword" required>
						<el-input v-model="ruleForm1.confirmPassword" placeholder="请确认新密码"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm',1)">确认修改</el-button>
						<el-button @click="resetForm()">取消</el-button>
					</el-form-item>
				</el-form>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/common/ZhuCard.vue";
import {postApi2, system_unbinding} from '@/api'
import {mapGetters} from "vuex";

export default {
  name: 'AccountSetting',
  components: { Card },
  data() {
    const validateAccount = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新账号'))
      } else if(value.length !== 11){
        callback(new Error('请输入11位数的手机号码'))
      } else if(!/^1\d{10}$/.test(value)){
        callback(new Error('手机号码格式不正确'))
      } else if(value === this.username){
        callback(new Error('新账号与原帐号一致'))
      } else {
        callback();
      }
    };
		const compareData = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入账号'));
			} else if (value !== this.ruleForm.new_account) {
				callback(new Error('两次输入账号不一致!'));
			} else {
				callback();
			}
		}
		const validatePassword = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入新密码'))
			} else callback()
		};
		const compareData1 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		}
    return {
      visible: false,
      username: '',
      ruleForm: {
				new_account: '',
				new_account_confirm: '',
				password: '',
				re_password: ''
      },
      rules: {
				new_account: [
          { required: true, validator: validateAccount, trigger: 'blur' }
        ],
				new_account_confirm: [
          { required: true, validator: compareData, trigger: 'blur' }
				],
				password: [
					{required: true, validator: validatePassword, trigger: 'blur'},
				],
				re_password: [
					{required: true, validator: compareData1, trigger: 'blur'},
				]
			},
			index: 0,
			ruleForm1: {
				oldPassword: '',
				password: '',
				confirmPassword: ''
			}
    }
  },
  created() {
    this.getData();
  },
	computed: {
		...mapGetters(['userInfo', 'menuConfig']),
		user_info() {
			return this.userInfo.userinfo
		}
	},
  methods: {
		onItem(val, ruleForm = 'ruleForm') {
			if (this.$refs[ruleForm])
				this.$refs[ruleForm].resetFields()
			// this.$refs[ruleForm].resetFields()
			this.index = val
		},
    getData() {
      const user = sessionStorage.getItem('USER_INFO')
      if(user) {
        const data = JSON.parse(user)
        const { userinfo: { username }, userid } = data
        this.id = userid
        this.username = username
      }
    },
    logout() {
      this.$utils.loginApi.clearLoginData()
      this.$router.replace('/login')
      this.$message.success({ message: '修改成功！请重新登录！' })
    },
    handleClick(type) {
      if(type) {
        this.$confirm('<div>是否确认解绑企业微信?</div>', '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              system_unbinding({ id: this.id }).then(() => {
                done()
                this.visible = true
              }).finally(() => {
                instance.confirmButtonLoading = false
              })
            } else {
              done()
            }
          }
        })
        return
      }
      this.visible = false
      this.logout()
    },
		submitForm(formName, index) {
			if (index === 0) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$_axios2.post('api/account-student/teacher/change-account', this.ruleForm, {logic: 1}).then(() => {
							this.logout()
						})
					}
				})
			}
			if (index === 1) {
				let {oldPassword, password, confirmPassword} = this.ruleForm1
				const data = {old_password: oldPassword, password, re_password: confirmPassword, id: this.user_info.id}
				console.log(this.ruleForm1)
				postApi2('/account/change-password', data).then(res => {
					this.$message.success({message: '修改密码成功！'})
					this.$utils.loginApi.clearLoginData()
					this.$router.replace('/login')
				})
			}
    },
		clearObject(obj) {
			for (let key in obj) {
				obj[key] = '';
			}
		},
    resetForm(formName) {
			if (!!formName)
				this.$refs[formName].resetFields()
			else this.clearObject(this.ruleForm1)
      this.$router.back();
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
